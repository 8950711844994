import React, { memo } from 'react'
import { BWPanel } from 'src/libs/qb-brand-web-components'

import { OverviewTitleContent } from '../shared/common'
import QuestsButton from './QuestsButton'

const OverviewMainPanelContent = ({
  children,
  panelCss,
  navItemsMoreLength,
  customCss,
  isQuestsEnabled,
  t
}) => {
  return (
    <>
      <BWPanel padding={15} css={[{ maxHeight: '500px' }, panelCss]}>
        <OverviewTitleContent
          navItemsMoreLength={navItemsMoreLength}
          css={[customCss, isQuestsEnabled && { gap: 15, padding: 15 }]}
        >
          {children}
        </OverviewTitleContent>
      </BWPanel>
      {isQuestsEnabled && <QuestsButton t={t} />}
    </>
  )
}

export default memo(OverviewMainPanelContent)
