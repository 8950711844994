import {
  formatCurrencyValue,
  titleCase,
  toUpper
} from 'src/libs/qb-brand-web-components'

import { commonParser, parseMembership } from './apiParser.helpers'
import {
  COINBASE_SIGNUP_URL,
  COINBASE_URL,
  CUSTOM_BRAND_ID,
  EXCHANGE_PROVIDER_DATA,
  MAX_CRYPTO_DECIMALS,
  DONATION_WEBSITE_URLS,
  BINANCE_SIGNUP_URL,
  BINANCE_PARTNER_PROGRAM_URL,
  BINANCE_VIEW_SHOP_URLS,
  COINBASE_BLUR_URL,
  MAX_USD_EXCHANGE_LIMIT,
  MIN_USD_EXCHANGE_LIMIT_FOR_MILES,
  MIN_USD_EXCHANGE_LIMIT
} from '../constants/pointsExchange'
import { LABEL_TEXT } from '../constants/messages'
import { TOKEN_WHITELIST_TYPE } from '../constants/token'
import {
  isExchangeProviderEmailType,
  isExistsElementFromList,
  isListNotEmpty
} from './app/app.helpers'
import { toDashCase, toLower, formatTicks } from './string.helpers'
import { numberCeil } from './number.helpers'
import { getNftCoverImage } from './nft.helpers'
import { LOYALTY_EVENT_TYPES } from '../constants/transactions'
import { RENAULT_BRAND_ID } from '../constants/brands'
import brandConfig from 'brandConfig'
import customConfig from 'customConfig'

const formatMembershipsToObject = (membershipData) => {
  if (membershipData && membershipData.length) {
    return membershipData.reduce((obj, data) => {
      const membership = parseMembership(data)
      const brand = membership.brand
      return brand && brand.id
        ? {
            ...obj,
            [brand.id]: {
              id: membership.id,
              membershipNumber: membership.membershipNumber
            }
          }
        : {}
    }, {})
  }
  return {}
}

const isProviderConnected = (memberships, provider) => {
  return Boolean(extractMembershipNumber(memberships, provider))
}

const extractMembershipNumber = (memberships, provider) => {
  if (
    memberships &&
    Object.keys(memberships).length > 0 &&
    provider &&
    provider.brandId
  ) {
    return memberships?.[provider?.brandId]?.membershipNumber
  }
  return ''
}

const exchangeProviderTitleForTransaction = (t, { name, id }, symbol) => {
  if (isCryptoBrand(id)) {
    return t('exchange.cryptocurrencies') + ` (${toUpper(symbol)})`
  } else if (isBrandIdPaypal(id)) {
    return t('exchange.fiat-currencies') + ` (${toUpper(symbol)})`
  } else if (isBrandIdGetChange(id)) {
    return t('exchange.donations') + ` (${toUpper(symbol)})`
  } else if (isTransactionForMiles(id)) {
    const type = EXCHANGE_PROVIDER_DATA[id]?.type
    return `${name}${type ? ` ${type}` : ''} ${titleCase(
      t(buildMilesOrPointsLabel({ isMiles: true }))
    )}`
  } else {
    return name
  }
}

const isTransactionForMiles = (brandId) => {
  return [CUSTOM_BRAND_ID.ETIHAD, CUSTOM_BRAND_ID.MILES_AND_MORE].includes(
    brandId
  )
}

const isBrandIdCoinbase = (brandId) => {
  return brandId === CUSTOM_BRAND_ID.COINBASE
}

const isBrandIdBinance = (brandId) => {
  return brandId === CUSTOM_BRAND_ID.BINANCE
}

const isCryptoBrand = (brandId) => {
  return isExistsElementFromList(
    [CUSTOM_BRAND_ID.COINBASE, CUSTOM_BRAND_ID.BINANCE],
    brandId
  )
}

const isBrandIdPaypal = (brandId) => {
  return brandId === CUSTOM_BRAND_ID.PAYPAL
}

const isBrandIdXoxo = (brandId) => {
  return brandId === CUSTOM_BRAND_ID.XOXO_DAY
}

const isBrandIdGetChange = (brandId) => {
  return brandId === CUSTOM_BRAND_ID.GET_CHANGE
}

const exchangeLabelForAccount = (t, provider) => {
  if (!provider) {
    return ''
  }
  const { name, symbol, brandId, isMiles, isCrypto, isFiat, type, brandName } =
    provider
  const isDonation = isTokenTypeDonation(type)
  let label
  if (isCrypto) {
    label = t('exchange.cryptocurrencies')
  } else if (isFiat) {
    label = t('exchange.fiat-currencies')
  } else if (isDonation) {
    label = t('exchange.donations')
  }
  return label
    ? label + ` (${brandName})`
    : exchangeProviderLabel({ name, symbol, brandId, isMiles })
}

const exchangeProviderLabel = ({ name, symbol }) => {
  return `${name} ${symbol ? `(${symbol})` : ''}`
}

const isTokenTypeMiles = (type) => {
  return type === TOKEN_WHITELIST_TYPE.MILES
}

const isTokenTypeNft = (type) => {
  return type === TOKEN_WHITELIST_TYPE.ERC_721
}

const isTokenTypeCrypto = (type) => {
  return type === TOKEN_WHITELIST_TYPE.CRYPTO
}

const isTokenTypeFiat = (type) => {
  return type === TOKEN_WHITELIST_TYPE.FIAT
}

const isTokenTypeGiftCard = (type) => {
  return type === TOKEN_WHITELIST_TYPE.GIFT_CARD
}

const isTokenTypeDonation = (type) => {
  return type === TOKEN_WHITELIST_TYPE.DONATION
}

const isTokenTypePoints = (type) => {
  return [TOKEN_WHITELIST_TYPE.ERC_20, TOKEN_WHITELIST_TYPE.EXTERNAL].includes(
    type
  )
}

const isTokenTypesForExchange = (type) => {
  return [
    TOKEN_WHITELIST_TYPE.ERC_20,
    TOKEN_WHITELIST_TYPE.EXTERNAL,
    TOKEN_WHITELIST_TYPE.MILES,
    TOKEN_WHITELIST_TYPE.CRYPTO,
    TOKEN_WHITELIST_TYPE.FIAT,
    TOKEN_WHITELIST_TYPE.DONATION,
    TOKEN_WHITELIST_TYPE.GIFT_CARD,
    TOKEN_WHITELIST_TYPE.MERCHANDISE
  ].includes(type)
}

const buildMilesOrPointsLabel = (provider) => {
  if (!provider) {
    return ''
  }
  const { isMiles, isCrypto } = provider
  return isMiles
    ? LABEL_TEXT.MILES
    : isCrypto
      ? LABEL_TEXT.CRYPTO
      : LABEL_TEXT.POINTS
}

const exchangeProviderSymbol = (t, provider) => {
  if (!provider) {
    return ''
  }
  const { symbol, isMiles } = provider
  return isMiles ? t(LABEL_TEXT.MILES) : symbol
}

const exchangeProviderAccountLabel = (t, { brandId }, isLower = false) => {
  const label = t(
    isExchangeProviderEmailType(brandId)
      ? 'common.email-address'
      : customConfig.accountNumberLabel
  )
  return isLower ? toLower(label) : label
}

const isExchangeAvailable = (exchangeWhitelist) => {
  return (
    isListNotEmpty(exchangeWhitelist.outgoing) ||
    brandConfig.features.enableCOEStorefront
  )
}

const isNftAvailable = (userBrandTokens) => {
  return isListNotEmpty(userBrandTokens?.nfts)
}

const filterExchangeProviderForExchange = (exchangeWhitelist) => {
  const partnersObj = exchangeWhitelist.outgoing.reduce((acc, partner) => {
    if (
      !isBrandIdTrollbeads(partner.brandId) &&
      isTokenTypesForExchange(partner.type)
    ) {
      const groupKey = toLower(partner.symbol)
      if (isTokenTypeCrypto(partner.type)) {
        if (!acc[groupKey]) {
          acc[groupKey] = partner
          acc[groupKey].additionalList = []
        } else {
          acc[groupKey].additionalList.push(partner)
        }
      } else {
        acc[partner.tokenId] = partner
      }
    }
    return acc
  }, {})
  return sortDataByNameASC(Object.values(partnersObj))
}

const filterExchangeProviderForManage = (exchangeWhitelist) => {
  const providers = {}
  exchangeWhitelist.outgoing.forEach((exchange) => {
    if (
      !providers[exchange.brandId] &&
      isTokenTypesForExchange(exchange.type)
    ) {
      providers[exchange.brandId] = exchange
    }
  })
  return sortDataByNameASC(Object.values(providers))
}

const isBrandIdTrollbeads = (brandId) => {
  return brandId === CUSTOM_BRAND_ID.TROLLBEADS_MEMBERSHIP_CLUB
}

const buildSignUpUrl = async (provider) => {
  const { isCrypto, isFiat, loyaltyProgramUrl, websiteUrl, brandId, metadata } =
    provider
  if (isCrypto) {
    return isBrandIdCoinbase(brandId) ? COINBASE_SIGNUP_URL : BINANCE_SIGNUP_URL
  } else if (isFiat) {
    return loyaltyProgramUrl
  } else {
    if (loyaltyProgramUrl) {
      return `${loyaltyProgramUrl}/${buildSignupPath(brandId)}`
    }
    return metadata?.links?.websiteUrl || websiteUrl || ''
  }
}

const buildVisitProgramUrl = (provider, defaultUrl) => {
  const { isCrypto, brandId } = provider
  return isCrypto && isBrandIdBinance(brandId)
    ? BINANCE_PARTNER_PROGRAM_URL
    : defaultUrl
}

const buildSignupPath = (brandId) => {
  return brandId === RENAULT_BRAND_ID ? 'registrazione' : 'signup'
}

const formatCryptoValue = (points, offramp = 0, cryptoExchange = 0) => {
  return cryptoExchange > 0
    ? Math.round(
        formatCurrencyValue((points * offramp) / cryptoExchange, 6) *
          MAX_CRYPTO_DECIMALS
      ) / MAX_CRYPTO_DECIMALS || 0
    : 0
}

const ceilCalculator = (rate, value) => {
  return formatTicks(Math.ceil(rate * value))
}

const floorCalculator = (value) => {
  return Math.floor(value) || 0
}

const sortDataByNameASC = (partners) => {
  return partners?.sort((a, b) => a?.name?.localeCompare(b?.name))
}

const sortExchangesByNameASC = (exchanges) => {
  return exchanges?.sort((a, b) => a?.brandName?.localeCompare(b?.brandName))
}

const buildCoinbaseShopUrl = (tokenName) => {
  const name = toDashCase(tokenName)
  return name === 'blur'
    ? COINBASE_BLUR_URL
    : `${COINBASE_URL}learn/crypto-basics/what-is-${name}`
}

const buildBinanceShopUrl = (tokenSymbol) => {
  return BINANCE_VIEW_SHOP_URLS[toLower(tokenSymbol)]
}

const isExchangeEventType = (loyaltyEventType) => {
  return [
    LOYALTY_EVENT_TYPES.EXCHANGE,
    LOYALTY_EVENT_TYPES.REVERT_EXCHANGE
  ].includes(loyaltyEventType)
}

const isNftSaleEventType = (loyaltyEventType) => {
  return loyaltyEventType === LOYALTY_EVENT_TYPES.NFT_SALE
}

const formattedNftsForRedemption = ({
  nftsForRedemption,
  currentBrandToken
}) => {
  const tokenOnramp = currentBrandToken?.token?.onramp
  return nftsForRedemption.map(({ id, onramp, name, metadata }) => {
    return {
      id,
      isNft: true,
      claimCount: 0,
      value: tokenOnramp > 0 ? numberCeil(onramp / tokenOnramp) : 0,
      name,
      metadata: {
        ...metadata,
        description: metadata?.description?.data,
        coverImage: getNftCoverImage(metadata)
      },
      website: metadata?.externalLink
    }
  })
}

const formattedNftsForSale = ({ nftsForSale, currentBrandToken }) => {
  const tokenOnramp = currentBrandToken?.token?.onramp
  return nftsForSale?.map(
    ({ nftId, usdPrice, claimCount, token: { id, name, metadata } }) => {
      return {
        id,
        nftId,
        isNft: true,
        isForSale: true,
        claimCount,
        value: tokenOnramp > 0 ? numberCeil(usdPrice / tokenOnramp) : 0,
        usdPrice,
        name,
        metadata: {
          ...metadata,
          description: metadata?.description?.data,
          coverImage: getNftCoverImage(metadata)
        },
        website: metadata?.externalLink
      }
    }
  )
}

const sortRedeemOptionsByValue = (rewardTypes, nfts) => {
  return [...rewardTypes, ...nfts]?.sort((a, b) => a.value - b.value)
}

const filterSameRedeemOptions = (formattedNfts, formattedSaleNfts) => {
  const redeemOptions = [...formattedNfts, ...formattedSaleNfts]
  const redeemOptionsArray = []
  const seenIds = {}
  redeemOptions.forEach((redeemOption) => {
    if (seenIds[redeemOption.id]) {
      const existingItemIndex = seenIds[redeemOption.id] - 1
      const existingItem = redeemOptionsArray[existingItemIndex]
      if (
        existingItem.metadata.exclusiveContent &&
        redeemOption.metadata.exclusiveContent
      ) {
        if (
          (existingItem.claimCount === 0 && redeemOption.claimCount === 0) ||
          (existingItem.claimCount > 0 && redeemOption.claimCount > 0)
        ) {
          if (redeemOption.value < existingItem.value) {
            redeemOptionsArray[existingItemIndex] = redeemOption
          }
        } else {
          redeemOptionsArray.push(redeemOption)
        }
      } else {
        if (redeemOption.value < existingItem.value) {
          redeemOptionsArray[existingItemIndex] = redeemOption
        }
      }
    } else {
      seenIds[redeemOption.id] = redeemOptionsArray.length + 1
      redeemOptionsArray.push(redeemOption)
    }
  })
  return redeemOptionsArray
}

const isTokenTypeHasStaticUrl = (type) => {
  return isExistsElementFromList(
    [
      TOKEN_WHITELIST_TYPE.CRYPTO,
      TOKEN_WHITELIST_TYPE.FIAT,
      TOKEN_WHITELIST_TYPE.DONATION,
      TOKEN_WHITELIST_TYPE.MERCHANDISE
    ],
    type
  )
}

const buildDonationWebsiteUrl = ({ symbol, websiteUrl }) => {
  return symbol ? DONATION_WEBSITE_URLS[toLower(symbol)] : websiteUrl
}

const selectExchangeRate = (cryptoExchanges, provider) => {
  return cryptoExchanges?.[toLower(provider?.symbol)]?.[provider?.brandId] || 0
}

const calculateMinMaxPoints = (
  { minUsdExchangeLimit, maxUsdExchangeLimit },
  value
) => {
  const minPoints = Math.ceil(minUsdExchangeLimit / value)
  const maxPoints = Math.floor(maxUsdExchangeLimit / value)
  return {
    minPoints,
    maxPoints
  }
}

const formatExchangeWhitelist = (data) => {
  const result = {
    outgoing: [],
    incoming: []
  }
  // Using outgoing paris for exchange to other tokens (crypto, points, miles, etc..)
  if (data.outgoing && data.outgoing.length) {
    result.outgoing = data.outgoing
      .filter(({ token }) => token.brand_id !== 'branded_redemption_shop')
      .map((pair) => {
        const { token, maxUsdExchangeLimit, minUsdExchangeLimit } =
          commonParser(pair)
        const { id, type, onramp, metadata, ...restAttrs } = token
        const currentToken = commonParser(data.token)
        return {
          description: metadata?.description?.data,
          tokenId: id,
          type,
          exchangeRate: formatCurrencyValue(currentToken.offramp / onramp, 2),
          isMiles: isTokenTypeMiles(type),
          isCrypto: isTokenTypeCrypto(type),
          isFiat: isTokenTypeFiat(type),
          metadata,
          maxUsdExchangeLimit,
          minUsdExchangeLimit,
          ...restAttrs
        }
      })
  }
  // Using incoming paris for buy points with miles (Miles and more for now)
  if (data.incoming && data.incoming.length) {
    result.incoming = data.incoming.map((pair) => {
      const { token, maxUsdExchangeLimit, minUsdExchangeLimit } =
        commonParser(pair)
      const { metadata, logoUrl, ...restAttrs } = token
      return {
        description: metadata?.description?.data,
        metadata,
        maxUsdExchangeLimit: maxUsdExchangeLimit || MAX_USD_EXCHANGE_LIMIT,
        minUsdExchangeLimit:
          minUsdExchangeLimit ||
          (isTokenTypeMiles(token.type)
            ? MIN_USD_EXCHANGE_LIMIT_FOR_MILES
            : MIN_USD_EXCHANGE_LIMIT),
        bgImage: logoUrl,
        ...restAttrs
      }
    })
  }
  return result
}

export {
  formatMembershipsToObject,
  isProviderConnected,
  exchangeProviderTitleForTransaction,
  exchangeProviderAccountLabel,
  isExchangeAvailable,
  isNftAvailable,
  exchangeProviderSymbol,
  buildSignUpUrl,
  buildVisitProgramUrl,
  buildMilesOrPointsLabel,
  formatCryptoValue,
  ceilCalculator,
  floorCalculator,
  sortDataByNameASC,
  sortExchangesByNameASC,
  exchangeProviderLabel,
  isTokenTypeMiles,
  isTokenTypeCrypto,
  buildCoinbaseShopUrl,
  buildBinanceShopUrl,
  filterExchangeProviderForExchange,
  exchangeLabelForAccount,
  isBrandIdTrollbeads,
  isCryptoBrand,
  isBrandIdCoinbase,
  isBrandIdBinance,
  isTokenTypePoints,
  extractMembershipNumber,
  isTokenTypeNft,
  filterExchangeProviderForManage,
  isExchangeEventType,
  formattedNftsForRedemption,
  sortRedeemOptionsByValue,
  isTokenTypeHasStaticUrl,
  isTokenTypeFiat,
  isBrandIdPaypal,
  isTokenTypeGiftCard,
  isBrandIdXoxo,
  isTokenTypeDonation,
  isBrandIdGetChange,
  buildDonationWebsiteUrl,
  formattedNftsForSale,
  filterSameRedeemOptions,
  isNftSaleEventType,
  selectExchangeRate,
  calculateMinMaxPoints,
  formatExchangeWhitelist
}
