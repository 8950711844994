import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  extractEmailFromUrl,
  usePreviousState
} from 'src/libs/qb-brand-web-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  LOG_IN_URL,
  PAGE_NOT_FOUND_URL,
  UNAUTHORIZED_URLS
} from '../constants/navigation'
import { confirmSignup, getCurrentUser, signOut } from '../store/actions/auth'
import { changeRewardsState } from '../store/actions/rewards'
import { changeAccountState } from '../store/actions/account'
import { REWARDS_PROFILE_DISPATCH } from '../store/reducers/rewards/rewards'
import Loading from '../components/shared/loading/Loading'
import { extractCognitoUserEmailMissing } from '../util/auth.helpers'
import { welcomeScreenLink } from '../util/siteLink.helpers'
import {
  isNotAuthenticated,
  isAuthenticated,
  isCheckingAuth
} from '../util/account.helpers'
import AccountCreationFailed from '../components/popups/signUp/AccountCreationFailed'
import { ACCOUNT_PROFILE_DISPATCH } from '../store/reducers/account/account'
import { setForceSignup } from '../util/local.helpers'
import {
  ACCOUNT_INIT_PROCESS_STEP,
  DEFAULT_CONTAINER_STATE
} from '../constants/containerStates'
import {
  isExchangeAvailable,
  isNftAvailable
} from '../util/pointsExchange.helpers'
import customConfig from 'customConfig'
import { getPaymentSessionDetails } from '../store/actions/exchange'

const requireAuth = (ChildComponent) => {
  const ComposedComponent = (props) => {
    const {
      authState,
      accountProfile,
      getCurrentUser,
      signOut,
      changeRewardsState,
      confirmSignup,
      changeAccountState,
      getPaymentSessionDetails
    } = props

    useEffect(() => {
      if (isNotAuthenticated(authState)) {
        getCurrentUser()
      }
    }, [])

    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const prevAuthState = usePreviousState(authState)

    useEffect(() => {
      if (isNotAuthenticated(authState)) {
        if (isAuthenticated(prevAuthState)) {
          navigate(LOG_IN_URL, { replace: true })
        } else if (
          isCheckingAuth(prevAuthState) ||
          isNotAuthenticated(prevAuthState)
        ) {
          if (searchParams.get('code') && !searchParams.get('state')) {
            changeRewardsState(
              REWARDS_PROFILE_DISPATCH.REWARD_CODE,
              searchParams.get('code')
            )
            navigate(welcomeScreenLink(), { replace: true })
          } else if (searchParams.get('signupCode')) {
            const email = extractEmailFromUrl(location.search)
            confirmSignup(email, searchParams.get('signupCode'))
            navigate(LOG_IN_URL, { replace: true })
          } else {
            const urlIndex = UNAUTHORIZED_URLS.findIndex(
              (url) => url === location.pathname
            )
            navigate(urlIndex < 0 ? PAGE_NOT_FOUND_URL : welcomeScreenLink(), {
              replace: true
            })
          }
        }
      } else if (
        isAuthenticated(authState) &&
        (isCheckingAuth(prevAuthState) || isNotAuthenticated(prevAuthState))
      ) {
        if (searchParams.get('code') && !searchParams.get('state')) {
          changeRewardsState(
            REWARDS_PROFILE_DISPATCH.REWARD_CODE,
            searchParams.get('code')
          )
        }
        if (searchParams.get('sessionId') && !searchParams.get('state')) {
          getPaymentSessionDetails(searchParams.get('sessionId'))
        }
        navigate(location.pathname, {
          replace: true,
          state: { alreadyAuthenticated: true }
        })
      }
    }, [authState, prevAuthState])

    const handleAccountCreationFailed = () => {
      setForceSignup(true)
      resetAccountInitStateAndSignOut()
    }

    const resetAccountInitStateAndSignOut = () => {
      changeAccountState(
        ACCOUNT_PROFILE_DISPATCH.ACCOUNT_INIT_STATE,
        DEFAULT_CONTAINER_STATE.NONE
      )
      signOut()
    }

    const renderPopup = () => {
      const { emailMissing } = props
      if (emailMissing) {
        signOut()
      }
      switch (accountProfile.accountInitState) {
        case ACCOUNT_INIT_PROCESS_STEP.CREATE_ACCOUNT_FAILED:
          return (
            <AccountCreationFailed
              onSubmit={handleAccountCreationFailed}
              label={customConfig.accountCreationFailedLabel}
            />
          )
        default:
          return null
      }
    }

    const checkIsAuthenticated = isAuthenticated(authState)
    return (
      <>
        {checkIsAuthenticated ? (
          <ChildComponent {...props} />
        ) : (
          <Loading main />
        )}
        {checkIsAuthenticated && renderPopup()}
      </>
    )
  }

  const mapStateToProps = ({
    authReducer,
    accountReducer,
    mainReducer,
    questReducer
  }) => {
    const { authProfile } = authReducer
    const { accountProfile } = accountReducer
    const { mainProfile, tokenProfile } = mainReducer
    const { authState, cognitoUser } = authProfile
    const emailMissing = extractCognitoUserEmailMissing(cognitoUser)
    const { loading, brandAppConfig, exchangeWhitelist, userBrandTokens } =
      mainProfile
    const { questProfile } = questReducer
    return {
      authState,
      loading,
      accountProfile,
      emailMissing,
      isExchangeEnabled: isExchangeAvailable(exchangeWhitelist),
      isNftEnabled: isNftAvailable(userBrandTokens),
      brandAppConfig,
      enablePurchasePoints: tokenProfile.enablePurchasePoints,
      isQuestsEnabled: questProfile.all.length || false
    }
  }

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        getCurrentUser,
        signOut,
        changeRewardsState,
        confirmSignup,
        changeAccountState,
        getPaymentSessionDetails
      },
      dispatch
    )

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent)
}

export default requireAuth
